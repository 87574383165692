import { Grid, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, styled, useTheme } from "@mui/system";
import { useState, useEffect, useContext } from "react";
import { LoginContext } from "../contexts/authContext";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SessionExpireContext } from "../contexts/sessionExpireContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import ResponsiveAppBar from "../components/CustomAppBar";
import Rating from "@mui/material/Rating";
import { rootPath, baseUrl } from "../utils/utils";
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
}));
const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));
const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "#87CEEB",
}));
const JWTRoot = styled(JustifyBox)(() => ({
  background: "#87CEEB",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: "90vw",
    minHeight: 400,
    margin: "1rem",
    // display: "flex",
    borderRadius: 0,
    alignItems: "center",
  },

  "& .MuiCardHeader-root": {
    background: "#1A2038",
    color: "#ffffff",
  },
  "& .MuiCardHeader-title": {
    textAlign: "center",
  },
  "& .MuiCardHeader-subheader": {
    textAlign: "center",
    color: "#ffffff",
  },
  "& .MuiCardContent-root": {
    width: "80vw",
  },
  "& .MuiSvgIcon-root": {
    color: "#ffffff",
  },
  "& .MuiTablePagination-selectLabel": {
    color: "#ffffff",
  },
  "& .MuiSelect-select": {
    color: "#ffffff",
  },
  "& .MuiTablePagination-displayedRows": {
    color: "#ffffff",
  },
}));

const ClientList = () => {
  const [loading, setLoading] = useState(false);
  const { value, setValue } = useContext(LoginContext);
  const { setSessionValue } = useContext(SessionExpireContext);
  const [rows, setRows] = useState([]);
  const [, setErr] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const columns = [
    {
      field: "client_status",
      headerName: "Status",
      width: 140,
      renderCell: (params) => BtnInfo(params, "toolFlg"),
    },
    {
      field: "id",
      headerName: "Id",
      sortable: true,
      width: 80,
    },
    { field: "client_name", headerName: "Name", width: 120 },
    { field: "email", headerName: "Email", width: 130 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      sortable: true,
      width: 120,
    },
    {
      field: "schedule_recall",
      headerName: "Schedule Recall",
      sortable: true,
      width: 160,
    },
    {
      field: "site_phasing",
      headerName: "Site Phasing",
      sortable: true,
      width: 120,
    },
    {
      field: "whatsapp_number",
      headerName: "WhatsApp",
      sortable: true,
      width: 120,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      width: 120,
    },
    {
      field: "site_ratio",
      headerName: "Site Ratio",
      sortable: true,
      width: 120,
    },
    {
      field: "profession",
      headerName: "Profession",
      sortable: true,
      width: 120,
    },
    {
      field: "rating",
      headerName: "Rating",
      sortable: true,
      width: 150,
      renderCell: (params) => RatingDisplay(params),
    },
    {
      field: "intent",
      headerName: "Intent",
      width: 90,
    },
    {
      field: "vehicle",
      headerName: "vehicle",
      sortable: true,
      width: 120,
    },
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenDialog = ({ id }) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleConfirmDelete = () => {
    deleteClient();
    setOpen(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getClientList();
  }, []);
  const getClientList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}list_users.php`, {
        headers: {
          Authorization: `Bearer ${value.data}`,
        },
      });
      if (response.status === 200) {
        if (response.data.data) {
          setRows(response.data.list);
        } else {
          setSessionValue(true);
          setValue(false);
        }
      }
      setLoading(false);
    } catch (e) {
      setErr("Bad request or Network Error");
      setLoading(false);
    }
  };
  function BtnInfo(params, dspFlgField) {
    // const router = useRouter();
    const navigate = useNavigate();
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: 25,
            height: 25,
            background: "#87CEEB",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "#1976d2 1px solid",
            borderRadius: "5",
            color: "#1A2038",
            margin: 2,
          }}
          onClick={() => {
            navigate(`${rootPath}editclient/${params.id}`);
          }}
        >
          <EditIcon size="small" style={{ color: "rgb(26, 32, 56)" }} />
        </div>
        <div
          style={{
            width: 25,
            height: 25,
            background: "#87CEEB",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "#1976d2 1px solid",
            borderRadius: "5",
            color: "#be1919",
            margin: 2,
          }}
          onClick={() => {
            handleClickOpenDialog(params);
          }}
        >
          <DeleteIcon size="small" color="red" style={{ color: "red" }} />
        </div>
      </div>
    );
  }
  function RatingDisplay(params) {
    console.log(params.row.rating);
    return <Rating name="read-only" value={params.row.rating} readOnly />;
  }
  const deleteClient = async () => {
    try {
      const json = {
        id: deleteId,
        client_status: false,
      };
      const response = await axios.post(`${baseUrl}update_status.php`, json, {
        headers: {
          Authorization: `Bearer ${value.data}`,
        },
      });
      if (response.status === 200) {
        if (response.data.data) {
          getClientList();
        } else {
          setSessionValue(true);
          setValue(false);
        }
      }
      setDeleteId(null);
    } catch (e) {}
  };
  return (
    <>
      <ResponsiveAppBar />

      <JWTRoot>
        {/* <Card className="card">
          <CardHeader title="Client List" action={<CommonLogout />} />
          <CardContent>
            
          </CardContent>
        </Card> */}
        <Grid container>
          <Grid item sm={12} xs={12}>
            <ContentBox>
              <div style={{ height: 500, width: "100%" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#1A2038" }}
                  onClick={() => {
                    navigate(`${rootPath}clientDetails`);
                  }}
                >
                  <AddIcon />
                </Button>

                <DataGrid
                  autoHeight
                  style={{ backgroundColor: "#1A2038", color: "#ffffff" }}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  loading={loading}
                />
              </div>
            </ContentBox>
          </Grid>
        </Grid>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Do you Want to Delete?"}
          </DialogTitle>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </JWTRoot>
    </>
  );
};
export default ClientList;
