import { LoadingButton } from "@mui/lab";
import {
  Card,
  Grid,
  TextField,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { H4 } from "../components/Typography";
import { useFormik } from "formik";
import { useState, useContext, useEffect } from "react";
import { LoginContext } from "../contexts/authContext";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { SessionExpireContext } from "../contexts/sessionExpireContext";
import { useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ResponsiveAppBar from "../components/CustomAppBar";
import Swal from "sweetalert2";
import { baseUrl } from "../utils/utils";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
}));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0.01)",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#87CEEB",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: "90vw",
    minHeight: 400,
    margin: "1rem",
    // display: "flex",
    borderRadius: 0,
    alignItems: "center",
  },

  "& .MuiCardHeader-root": {
    background: "#FF385C",
    color: "#ffffff",
  },
  "& .MuiCardHeader-title": {
    textAlign: "center",
  },
  "& .MuiCardHeader-subheader": {
    textAlign: "center",
    color: "#ffffff",
  },
  "& .MuiCardContent-root": {
    width: "80vw",
  },
  ".addAsterisk::after": {
    content: '"*"',
    color: "red",
    marginLeft: "2px",
  },
}));
const initialValues = {
  clientName: "",
  phoneNumber: "",
  email: "",
  whatsApp: "",
  intent: "Very Interested",
  sitePhasing: "East",
  schedule: dayjs(new Date()),
  siteRatio: "30x40",
  location: "",
  vehicle: "",
  profession: "",
  rating: 0,
};
const EditClient = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [sDate, setSdate] = useState(dayjs(new Date()));
  const { value, setValue } = useContext(LoginContext);
  const { setSessionValue } = useContext(SessionExpireContext);
  const [open, setOpen] = useState(true);
  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const year = sDate.$d.getFullYear();
      const month = ("0" + (sDate.$d.getMonth() + 1)).slice(-2);
      const day = ("0" + sDate.$d.getDate()).slice(-2);
      const formattedDate = `${year}-${month}-${day}`;
      const json = {
        id: id,
        client_name: values.clientName,
        phone_number: values.phoneNumber,
        email: values.email,
        whatsapp_number: values.whatsApp,
        intent: values.intent,
        site_phasing: values.sitePhasing,
        schedule_recall: formattedDate,
        site_ratio: values.siteRatio,
        location: values.location,
        vehicle: values.vehicle,
        profession: values.profession,
        rating: values.rating,
      };
      const response = await axios.post(`${baseUrl}update.php`, json, {
        headers: {
          Authorization: `Bearer ${value.data}`,
        },
      });
      if (response.status === 200) {
        if (response.data.data) {
          Swal.fire({
            title: "Success!",
            text: "Client Details updated Successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          setSessionValue(true);
          setValue(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getClient();
  }, []);
  const getClient = async () => {
    try {
      const response = await axios.get(`${baseUrl}get_client.php?id=${id}`, {
        headers: {
          Authorization: `Bearer ${value.data}`,
        },
      });
      setOpen(false);
      if (response.status === 200) {
        if (response.data.data && response.data.list) {
          formik.setValues({
            clientName: response.data.list.client_name,
            phoneNumber: response.data.list.phone_number,
            email: response.data.list.email,
            whatsApp: response.data.list.whatsapp_number,
            intent: response.data.list.intent,
            sitePhasing: response.data.list.site_phasing,
            schedule: dayjs(new Date(response.data.list.schedule_recall)),
            location: response.data.list.location,
            vehicle: response.data.list.vehicle,
            siteRatio: response.data.list.site_ratio,
            profession: response.data.list.profession,
            rating: response.data.list.rating,
          });
        } else if (response.data.data && response.data.message) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        } else {
          setSessionValue(true);
          setValue(false);
        }
      }
    } catch (e) {
      console.log("EEEEEEEEEE", e);
      //   setErr("Bad request or Network Error");
    }
  };
  // form field validation schema
  const validationSchema = Yup.object().shape({
    // clientName: Yup.string().required("Client Name Required!"),
    // phoneNumber: Yup.string()
    //   .min(10, "invalid phone number!")
    //   .max(10, "invalid phone number!")
    //   .required("Phone number is required!"),
    // email: Yup.string()
    //   .email("Invalid Email address!")
    //   .required("Email is required!"),
    // whatsApp: Yup.string()
    //   .min(10, "invalid phone number")
    //   .max(10, "invalid phone number")
    //   .required("WhatsApp number is required!"),
    // intent: Yup.string().required("intent required!"),
    // sitePhasing: Yup.string().required("required!"),
    // schedule: Yup.string().required("required!"),
    // siteRatio: Yup.string().required("required!"),
    // location: Yup.string().required("required!"),
    // vehicle: Yup.string().required("required!"),
    // profession: Yup.string().required("required!"),
    // rating: Yup.string().required("required!"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });
  return (
    <>
      {" "}
      <ResponsiveAppBar />
      <JWTRoot>
        <Card className="card">
          <CardHeader
            title="Client Details"
            subheader="Please Enter Details Below"
          />
          <CardContent>
            <Grid container>
              <Grid item sm={12} xs={12}>
                <ContentBox>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={4}>
                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Name</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="clientName"
                              variant="standard"
                              onBlur={formik.handleBlur}
                              value={formik.values.clientName}
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.clientName &&
                                formik.errors.clientName
                              }
                              error={
                                formik.errors.clientName &&
                                formik.touched.clientName
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Phone Number</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="number"
                              name="phoneNumber"
                              variant="standard"
                              onBlur={formik.handleBlur}
                              value={formik.values.phoneNumber}
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.phoneNumber &&
                                formik.errors.phoneNumber
                              }
                              error={
                                formik.errors.phoneNumber &&
                                formik.touched.phoneNumber
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">WhatsApp Number</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="number"
                              name="whatsApp"
                              variant="standard"
                              onBlur={formik.handleBlur}
                              value={formik.values.whatsApp}
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.whatsApp &&
                                formik.errors.whatsApp
                              }
                              error={
                                formik.errors.whatsApp &&
                                formik.touched.whatsApp
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Email</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="email"
                              name="email"
                              variant="standard"
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.email && formik.errors.email
                              }
                              error={
                                formik.errors.email && formik.touched.email
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Location</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="location"
                              variant="standard"
                              onBlur={formik.handleBlur}
                              value={formik.values.location}
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.location &&
                                formik.errors.location
                              }
                              error={
                                formik.errors.location &&
                                formik.touched.location
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Site Ratio</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="siteRatio"
                              onBlur={formik.handleBlur}
                              value={formik.values.siteRatio}
                              onChange={formik.handleChange}
                              // helperText={touched.intent && errors.intent}
                              error={
                                formik.errors.siteRatio &&
                                formik.touched.siteRatio
                              }
                            >
                              <FormControlLabel
                                value="30x40"
                                control={<Radio />}
                                label="30x40"
                              />
                              <FormControlLabel
                                value="30x50"
                                control={<Radio />}
                                label="30x50"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Site Phasing</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <Select
                              fullWidth
                              variant="standard"
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="sitePhasing"
                              onBlur={formik.handleBlur}
                              value={formik.values.sitePhasing}
                              onChange={formik.handleChange}
                              error={
                                formik.errors.sitePhasing &&
                                formik.touched.sitePhasing
                              }
                            >
                              <MenuItem value={"East"}>East</MenuItem>
                              <MenuItem value={"West"}>West</MenuItem>
                              <MenuItem value={"North"}>North</MenuItem>
                              <MenuItem value={"South"}>South</MenuItem>
                              <MenuItem value={"Northeast"}>Northeast</MenuItem>
                              <MenuItem value={"Northwest"}>Northwest</MenuItem>
                              <MenuItem value={"Southeast"}>Southeast</MenuItem>
                              <MenuItem value={"Southwest"}>Southwest</MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Schedule Recall</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disablePast
                                format="DD/MM/YYYY"
                                name="schedule"
                                onBlur={formik.handleBlur}
                                value={formik.values.schedule}
                                onChange={(value) => {
                                  setSdate(value);
                                  formik.handleChange("schedule", value);
                                }}
                                slotProps={{
                                  textField: {
                                    variant: "standard",
                                    fullWidth: true,
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Profession</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              type="text"
                              name="profession"
                              variant="standard"
                              onBlur={formik.handleBlur}
                              value={formik.values.profession}
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.profession &&
                                formik.errors.profession
                              }
                              error={
                                formik.errors.profession &&
                                formik.touched.profession
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Rating</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <Rating
                              name="rating"
                              onBlur={formik.handleBlur}
                              value={formik.values.rating}
                              onChange={formik.handleChange}
                              helperText={
                                formik.touched.rating && formik.errors.rating
                              }
                              error={
                                formik.errors.rating && formik.touched.rating
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Intent</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="intent"
                              onBlur={formik.handleBlur}
                              value={formik.values.intent}
                              onChange={formik.handleChange}
                              error={
                                formik.errors.intent && formik.touched.intent
                              }
                            >
                              <FormControlLabel
                                value="Very Interested"
                                control={<Radio />}
                                label="Very Interested"
                              />
                              <FormControlLabel
                                value="Interested"
                                control={<Radio />}
                                label="Interested"
                              />
                              <FormControlLabel
                                value="Might Be"
                                control={<Radio />}
                                label="Might Be"
                              />
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Grid container>
                          <Grid item sm={3} xs={12}>
                            <H4 className="addAsterisk">Vehicle</H4>
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="vehicle"
                              onBlur={formik.handleBlur}
                              value={formik.values.vehicle}
                              onChange={formik.handleChange}
                              // helperText={touched.intent && errors.intent}
                              error={
                                formik.errors.vehicle && formik.touched.vehicle
                              }
                            >
                              <FormControlLabel
                                value="Yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="No"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        justifyContent="center"
                        alignItems="center"
                        item
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingButton
                          type="submit"
                          style={{ backgroundColor: "#FF385C" }}
                          loading={loading}
                          variant="contained"
                          sx={{ my: 2 }}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </form>
                </ContentBox>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </JWTRoot>
    </>
  );
};
export default EditClient;
