import { createContext, useCallback } from "react";
import { useState } from "react";

const sessionExpireContextParams = {
  sessionValue: false,
  setSessionValue: (value) => {},
};

export const SessionExpireContext = createContext(sessionExpireContextParams);

export const useSessionExpireContext = () => {
  const [sessionValue, setSessionValue] = useState(false);
  const setterFunc = useCallback((current) => {
    setSessionValue(current);
  }, []);

  return {
    sessionValue: sessionValue,
    setSessionValue: setterFunc,
  };
};
