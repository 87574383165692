import Router from "./router/router";
import { BrowserRouter } from "react-router-dom";
import { LoginContext, useLoginContext } from "./contexts/authContext";
import {
  SessionExpireContext,
  useSessionExpireContext,
} from "./contexts/sessionExpireContext";
const App = () => {
  const loginContext = useLoginContext();
  const sessionContext = useSessionExpireContext();
  return (
    <LoginContext.Provider value={loginContext}>
      <SessionExpireContext.Provider value={sessionContext}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </SessionExpireContext.Provider>
    </LoginContext.Provider>
  );
};

export default App;
