import { createContext, useCallback } from "react";
import { useState } from "react";
import { getLoginCookies, setLoginCookies } from "./../utils/utils";

const loginContextParams = {
  value: getLoginCookies(),
  setValue: (value) => {},
};

export const LoginContext = createContext(loginContextParams);

export const useLoginContext = () => {
  const [value, setValue] = useState(getLoginCookies());
  const setterFunc = useCallback((current) => {
    setValue(current);
    setLoginCookies(current);
  }, []);

  return {
    value: value,
    setValue: setterFunc,
  };
};
