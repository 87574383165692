import * as React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ClientList from "../pages/ClientList";
import AdminLogin from "../pages/Login";
import { useContext } from "react";
import { LoginContext, useLoginContext } from "../contexts/authContext";
import ClientDetails from "../pages/ClientDetails";
import EditClient from "../pages/EditClient";
import { rootPath } from "../utils/utils";

const Router = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route>
          <Route
            path={rootPath}
            element={
              <AuthenticationPage>
                <AdminLogin />
              </AuthenticationPage>
            }
          />
          <Route
            path={`${rootPath}clientlist`}
            element={
              <RequireAuth>
                <ClientList />
              </RequireAuth>
            }
          />
          <Route
            path={`${rootPath}editclient/:id`}
            element={
              <RequireAuth>
                <EditClient />
              </RequireAuth>
            }
          />
          <Route
            path={`${rootPath}clientdetails`}
            element={
              <RequireAuth>
                <ClientDetails />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AuthProvider>
  );
};

const AuthProvider = ({ children }) => {
  const loginContext = useLoginContext();
  return (
    <LoginContext.Provider value={loginContext}>
      {children}
    </LoginContext.Provider>
  );
};

const RequireAuth = ({ children }) => {
  const { value } = useContext(LoginContext);
  let location = useLocation();
  if (!value) {
    return <Navigate to={rootPath} state={{ from: location }} replace />;
  }
  return children;
};

const AuthenticationPage = ({ children }) => {
  const { value } = useContext(LoginContext);
  let location = useLocation();
  if (value) {
    return (
      <Navigate
        to={`${rootPath}clientlist`}
        state={{ from: location }}
        replace
      />
    );
  }
  return children;
};

const PageNotFound = () => {
  return <h3>404 page not found</h3>;
};

export default Router;
