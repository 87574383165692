export const getLoginCookies = () => {
  const data = localStorage.getItem("userDetails");
  return data === null ? false : JSON.parse(data);
};
export const setLoginCookies = (data) => {
  return localStorage.setItem("userDetails", JSON.stringify(data));
};
export const rootPath = process.env.NODE_ENV === "development" ? "/" : "/";

export const baseUrl = "https://vijethalifespaces.in/api/";
