import * as React from "react";
import { LoadingButton } from "@mui/lab";
import { Card, Grid, TextField, Snackbar } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useContext, useEffect } from "react";
import { LoginContext } from "../contexts/authContext";
import { SessionExpireContext } from "../contexts/sessionExpireContext";
import axios from "axios";
import { rootPath, baseUrl } from "../utils/utils";
const FlexBox = styled(Box)(() => ({ display: "flex", alignItems: "center" }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: "center" }));

const ContentBox = styled(Box)(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
  background: "rgba(0, 0, 0, 0.01)",
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center",
  },
}));

// inital login credentials
const initialValues = {
  email: "",
  password: "",
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be 6 character length")
    .required("Password is required!"),
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required!"),
});
const AdminLogin = () => {
  const { setValue } = useContext(LoginContext);
  const { sessionValue, setSessionValue } = useContext(SessionExpireContext);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(`${baseUrl}login.php`, {
        email: values.email,
        password: values.password,
      });
      if (response.status === 200) {
        if (response.data.data) {
          setValue({ data: response.data.token });
          navigate(`${rootPath}clientlist`);
        } else {
          setErr(response.data.message);
        }
      }
      setLoading(false);
    } catch (e) {
      setErr("Bad request or Network Error");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (sessionValue) {
      setTimeout(() => {
        setSessionValue(false);
      }, 5000);
    }
  }, []);

  return (
    <JWTRoot>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <JustifyBox p={4} height="100%" sx={{ minWidth: 320 }}>
              <img src="/assets/images/vijetha-logo.png" width="100%" alt="" />
            </JustifyBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <ContentBox>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      error={Boolean(errors.password && touched.password)}
                      sx={{ mb: 1.5 }}
                    />

                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ my: 2 }}
                    >
                      Login
                    </LoadingButton>
                    {err.length > 0 && <div>{err}</div>}
                  </form>
                )}
              </Formik>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={sessionValue}
        message="Session Expired"
        key={"top"}
      />
    </JWTRoot>
  );
};
export default AdminLogin;
